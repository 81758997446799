import { render, staticRenderFns } from "./goodsDetail.vue?vue&type=template&id=5e9430f5&scoped=true&"
import script from "./goodsDetail.vue?vue&type=script&lang=js&"
export * from "./goodsDetail.vue?vue&type=script&lang=js&"
import style0 from "./goodsDetail.vue?vue&type=style&index=0&id=5e9430f5&prod&lang=less&scoped=true&"
import style1 from "./goodsDetail.vue?vue&type=style&index=1&id=5e9430f5&prod&lang=less&"
import style2 from "./goodsDetail.vue?vue&type=style&index=2&id=5e9430f5&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e9430f5",
  null
  
)

export default component.exports