<template>
  <div class="goods-detail-page">
    <div class="my-swipe">
      <van-swipe :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="item in product.imgs">
          <van-image :src="item" alt=""/>
<!--          <van-image :src="require('@/assets/images/6.jpg')" alt=""/>-->
        </van-swipe-item>
      </van-swipe>

      <div class="time" v-show="product.libckflag ===0">
        <span>距结束还剩</span>
        <van-count-down  :time="product.time" format="DD天 HH : mm : ss"/>
      </div>
    </div>

    <div class="goodsInfo">
      <div class="goodsName van-multi-ellipsis--l2">
        <span class="ys" v-show="product.libckflag ===0">预售</span>
        <span class="xh" v-show="product.libckflag ===1">现货</span>
        {{ product.name }}
      </div>


      <div class="handle">
        <div class="price">
          <span>¥</span>
          <span>{{ product.price }}</span>
        </div>

        <div class="tagList">
          <div class="tag" v-show="product.deliveryMode ==='2'">配送到校</div>
          <div class="tag" v-show="product.libckflag ===0">预售完生产</div>
          <div class="tag" v-show="product.deliveryMode ==='1'">快递到家</div>
        </div>
      </div>

      <warm-prompt  v-if="product.tipmemo && ''!==product.tipmemo" :reminder="product.tipmemo"/>
    </div>


    <div class="hr"></div>


    <div class="bottom">
      <div class="item" v-if="null == product.storeId">
        <img src="./../../assets/images/goods/buyMustSee/bkts.png" alt="">

        <div class="list">
          <p>
            <img src="./../../assets/images/goods/buyMustSee/tips.png" alt="">
            <span class="key">配送方式：</span>
            <span class="value" v-show="product.deliveryMode ==='2'">配送到校</span>
            <span class="value" v-show="product.deliveryMode ==='1'">快递到家</span>
          </p>
          <p v-show="product.deliverGoodsTemp === '1'">
            <img src="./../../assets/images/goods/buyMustSee/tips.png" alt="">
            <span class="key">下单<span class="value" v-text="product.day"/>天内发货：</span>
          </p>
          <p v-show="product.deliverGoodsTemp === '2'">
            <img src="./../../assets/images/goods/buyMustSee/tips.png" alt="">
                        <span class="key">预计配送时间：</span>
                        <span class="value">{{ product.deliverGoodsDate }}</span>
          </p>
          <p v-show="product.deliverGoodsTemp === '3'">
            <span class="value" v-html="product.deliverGoodsContent"></span>
          </p>
          <p>
            <img src="./../../assets/images/goods/buyMustSee/tips.png" alt="">
            <span class="key">快递费用：</span>
            <span v-if="product.deliveryMode === '2'" class="value">0元</span>
            <span v-if="null != deliverySet && product.deliveryMode === '1'" class="value">首{{deliverySet.startStandards}}件，邮费{{deliverySet.startFees}}元，
            每续{{deliverySet.addStandards}}件，邮费加{{deliverySet.addFees}}元，
            {{ null != deliverySet.freeShippingNum && undefined !== deliverySet.freeShippingNum?"件数达到"+deliverySet.freeShippingNum+"件包邮，":""}}
            {{ null != deliverySet.capping && undefined !== deliverySet.capping ?"邮费封顶"+deliverySet.capping+"元":""}}</span>
          </p>
        </div>
      </div>


      <div class="item">
        <img src="./../../assets/images/goods/buyMustSee/wxts.png" alt="">

        <div class="tips" v-if="product.mainmemo && ''!==product.mainmemo" v-html="product.mainmemo">
<!--          亲爱的家长朋友，请您一定了解清楚以上重要信息，根据实际需求购买。-->
        </div>
      </div>

    </div>

    <div class="hr half"></div>

    <div class="goodsDeail">
      <div class="title">商品详情</div>
      <div class="content" v-html="product.introduction">
<!--        <img src="./../../assets/images/goods/goodsDetail/test/goods.png" alt="">
        <img src="./../../assets/images/goods/goodsDetail/test/goods.png" alt="">
        <img src="./../../assets/images/goods/goodsDetail/test/goods.png" alt="">-->
      </div>
    </div>

    <div class="hr" style="height: 55px;"></div>

    <van-goods-action v-if="!hiddenToolbar">
      <van-goods-action-icon>
        <template #icon>
          <img class="goods-action-icon" src="./../../assets/images/goods/goodsDetail/kefu.png" alt="">
        </template>
        <template #default>
          <span class="goods-action-text">客服</span>
        </template>
      </van-goods-action-icon>
      <van-goods-action-icon :badge="shoppingCartNum" @click="$router.push('/cart')">
        <template #icon>
          <img class="goods-action-icon" src="./../../assets/images/goods/goodsDetail/cart.png" alt="">
        </template>
        <template #default>
          <span class="goods-action-text">购物车</span>
        </template>
      </van-goods-action-icon>
      <van-goods-action-button color="#FF553F" text="加入购物车" @click="onJoinCart"/>
      <van-goods-action-button color="#D53A26" text="立即购买" @click="onBuy"/>
    </van-goods-action>

    <sku ref="sku"/>
    <new-sku ref="newSku"/>
    <global-web-socket uri="/mall/ws/info" @setload="setLoad"></global-web-socket>
  </div>
</template>

<script>
import GlobalWebSocket from '@/components/WebSocket/GlobalWebSocket'
import sku from './components/sku.vue'
import newSku from "./components/newSku/newSku.vue";
import WarmPrompt from './components/warmPrompt.vue'
import {mapState} from 'vuex'
import {mapActions} from "vuex";
import Vue from "vue";
import {transferDate} from "../../util/util";
export default {
  components: {sku, WarmPrompt,GlobalWebSocket,newSku},
  name: "goodsDetail",
  props: {
    id: {default: ''}, // 商品ID， 不管是 /goodsDetail/123 还是通过组件， 都会到这
    hiddenToolbar: {default: false, type: Boolean}, // 是否隐藏下面的操作拦。 像上架审核这个页面， 只是把商品详情当成一个组件 就不需要操作拦
  },
  watch:{
    id:{
      handler(val){
        if(''!==val){
          this.id = val
          this.queryProduct();
        }
      },
      immediate:true,
    }
  },
  computed: {
    ...mapState(['shoppingCartNum']),
  },
  data() {
    return {
      time: 30 * 60 * 60 * 1000,
      product:{},
      deliverySet:{},
    }
  },
  methods: {
    ...mapActions(['queryShoppingCartNum']),
    setLoad(msgEvent) {
      this.$dialog.alert({
        message: msgEvent.data,
      }).then(() => {
        this.queryProduct();
      });
    },
    /**
     * 加入购物车
     */
    onJoinCart() {
      this.$refs.newSku.showPop()
    },
    /**
     * 立即购买
     */
    onBuy() {
      this.$refs.newSku.showPop()
    },
    queryProduct(){
      if(this.id){
        this.$http.get(`/product/h5_product/queryProductInfo/${this.id}`).then(res => {
          let product = res.data.product;
          this.deliverySet = res.data.deliverySet;
          product.time = transferDate(new Date(product.lmtime.replace(/-/g, "/"))).getTime() - Date.now();
          product.imgs = product.image.split(',');
          this.product = product;
          this.$refs.sku.id= product.id;
          this.$refs.newSku.id= product.id;
        }).catch(err => {
          console.log(err)
        })
      }
      this.queryShoppingCartNum({userId:Vue.prototype.userId});
    }
  },
  activated() {
    // if(this.id){
    //   this.queryProduct();
    // }
  },
  mounted() {
    /**
     * /goodsDetail/123
     * <goods-detail :id="123" />
     * 两种方式都使用this.id 获取商品id
     */
    // 商品ID， 不管是 /goodsDetail/123 还是通过组件， 都会到这
  }
}

</script>
<style lang="less" scoped>
.hr {
  height: 8px;
  background-color: #f4f4f4;

  &.half {
    height: 5px;
  }
}

.my-swipe {
  position: relative;

  .van-image {
    height: 313px;
    width: 100vw;
  }

  .time {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    height: 36px;
    background: rgba(230, 0, 18, .5);
    border-radius: 5px 5px 0px 0px;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 7px;


    font-size: 12px;

    color: #f4f4f4;
    line-height: 1;

    span {
      font-weight: bold;
      margin-right: 10px;
    }
  }

  .van-count-down {
    font-size: 12px;
    font-weight: bold;
    color: #f4f4f4;
    line-height: 1;
    letter-spacing: 1px;

  }
}

.goodsInfo {
  padding: 22px 18px 14px 18px;

  .goodsName {
    .ys {
      background: linear-gradient(264deg, #f58130, #ef2514);
      border-radius: 2px;

      font-size: 12px;
      font-weight: 500;
      color: #F4F4F4;
      line-height: 50px;
      line-height: 14px;

      padding: 0 5px;
      margin-right: 5px;
    }

    .xh {
      width: 32px;
      background: linear-gradient(264deg, #4db3ee, #00A0E9);
      border-radius: 2px;

      font-size: 12px;
      font-weight: 500;
      color: #F4F4F4;
      line-height: 12px;
      text-align: center;
      padding: 2px 0;
      display: inline-block;
      margin-right: 3px;

    }

    font-size: 15px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    padding-right: 0;
  }


  .handle {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    .price {
      color: #D53A26;
      font-weight: 500;

      span:first-child {
        font-size: 12px;
        margin-right: 3px;
        font-weight: bold;
      }

      span:last-child {
        font-size: 19px;
        font-weight: bold;
      }
    }

    .tagList {
      margin-left: 13px;

      .tag {
        border: 1px solid #FF0000;
        border-radius: 2px;
        padding: 3px 7px;
        font-size: 10px;
        font-weight: 400;
        color: #FF0000;
        line-height: 1;
        display: inline-block;
        margin-right: 11px;

        &.plain {
          padding-left: 0;
          padding-right: 0;
          color: #666;
          border: none;
        }
      }
    }

  }


}


.bottom {
  padding: 14px 18px;

  .item {
    > img {
      width: 100%;
    }

    .list {
      padding-bottom: 31px;

      p {
        margin-top: 16px;
        line-height: 12px;

        img {
          height: 12px;
          width: 12px;
          margin-right: 7px;
        }

        span {
          font-size: 12px;

          &.key {
            color: #999;
          }

          &.value {
            color: #212121;
          }
        }
      }
    }
  }
}

.goodsDeail {
  .title {
    font-size: 14px;
    color: #212121;
    border-bottom: 1px solid #ECECEC;
    height: 41px;
    line-height: 40px;
    text-align: center;
  }

}

.goods-action-icon {
  height: 18px;
  width: 18px;
}

.goods-action-text {
  font-size: 10px;
  color: #999;
}

::v-deep .van-goods-action-button--first {
  margin-right: 9px;
}

::v-deep .van-goods-action-button {
  border-radius: 100px;
  height: 35px;
  line-height: 35px;
}
</style>

<style lang="less">
.goods-detail-page {
  .goodsDeail {
    .content {
      color: #999;
      line-height: 17px;
      width: 100%;
      margin: auto;
      position: relative;
      overflow: auto;
      img {
        max-width: 100%; /* 图片最大宽度不超过容器宽度 */
        max-height: 100%; /* 图片最大高度不超过容器高度 */
        object-fit: contain;
      }
      font-size: 14px;
      padding: 15px;
    }
  }
}
</style>

<style lang="less">
.tips {
  color: #999;
  line-height: 17px;
  width: 100%;
  margin: auto;
  position: relative;
  overflow: auto;
  img {
    max-width: 100%; /* 图片最大宽度不超过容器宽度 */
    max-height: 100%; /* 图片最大高度不超过容器高度 */
    object-fit: contain;
  }
  font-size: 14px;
  padding: 15px;
}
</style>
